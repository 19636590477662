/**
 * Nosy Call To Action
 *
 * @export CallToAction
 */
import {
    Flex,
    Heading, Stack,
    Text
} from '@chakra-ui/react'
import React from 'react'
import { useSiteSettings } from '../../hooks/use-site-settings'
import {
    formatFunctionalPhoneNumber,
    formatMultiLineString
} from '../../utils/helpers'
import Link from '../link/link'

const CallToAction = () => {
    // Grab defaults from Sanity SiteSettings
    const { contactPoints = {} } = useSiteSettings()

    // Destructure contact points
    const { email = '', phone = '', address = '' } = contactPoints

    return (
        <Flex
            width='full'
            role='contentinfo'
            mx='auto'>
            <Stack
                direction={['column', 'column', 'row']}
                width='full'
                spacing={[12, 20, 24]}>
                <Stack direction='column' spacing={16} flex={1}>
                    <Heading
                        as='h3'
                        fontSize={['5xl', '7xl']}
                        width='min'
                        color='gray.100'
                        whiteSpace='break-spaces'
                        lineHeight='normal'>
                            Let's work together
                    </Heading>
                </Stack>
                <Stack direction='column' spacing={16} flex={1}>
                    <Stack direction='column' spacing={8}>
                        <Stack
                            direction={[
                                'column',
                                'column',
                                'column',
                                'row'
                            ]}
                            justifyContent='space-between'
                            alignItems={[
                                'flex-start',
                                'flex-start',
                                'flex-start',
                                'center'
                            ]}
                            py={3}
                            borderBottom='1px'
                            borderColor='gray.600'>
                            <Heading
                                as='h4'
                                fontSize={['2xl', null, '3xl']}
                                color='gray.100'
                                pr={12}>
                                    Email
                            </Heading>
                            <Link
                                to={`mailto:${email}`}
                                color='gray.100'
                                fontSize={['xl', '2xl']}>
                                {email}
                            </Link>
                        </Stack>
                        <Stack
                            direction={[
                                'column',
                                'column',
                                'column',
                                'row'
                            ]}
                            justifyContent='space-between'
                            alignItems={[
                                'flex-start',
                                'flex-start',
                                'flex-start',
                                'center'
                            ]}
                            py={3}
                            borderBottom='1px'
                            borderColor='gray.600'>
                            <Heading
                                as='h4'
                                fontSize={['2xl', null, '3xl']}
                                color='gray.100'
                                pr={12}>
                                    Telephone
                            </Heading>
                            <Link
                                to={`tel:${formatFunctionalPhoneNumber(
                                    phone
                                )}`}
                                color='gray.100'
                                fontSize={['xl', '2xl']}>
                                {phone}
                            </Link>
                        </Stack>
                        <Stack
                            direction={[
                                'column',
                                'column',
                                'column',
                                'row'
                            ]}
                            justifyContent='space-between'
                            alignItems={[
                                'flex-start',
                                'flex-start',
                                'flex-start',
                                'center'
                            ]}
                            py={3}
                            borderBottom='1px'
                            borderColor='gray.600'>
                            <Heading
                                as='h4'
                                fontSize={['2xl', null, '3xl']}
                                color='gray.100'
                                pr={12}>
                                    Office
                            </Heading>
                            <Text
                                textAlign={[ 'left', null, null, 'right' ]}
                                fontSize={['xl']}
                                color='gray.100'>
                                {formatMultiLineString( address )}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Flex>
    )
}

export default CallToAction
