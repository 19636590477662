/**
 * Nosy Work Index
 *
 * @export WorkIndex
 */

import {
    Box,
    Center,
    Container,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Tag,
    Text,
    useBreakpointValue,
    Wrap,
    WrapItem
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import CallToAction from '../../components/call-to-action/call-to-action'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_WORK_INDEX_QUERY($ids: [String]) {
        allSanityWork(
            filter: { id: { in: $ids } }
            sort: { fields: order, order: ASC }
        ) {
            totalCount
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    heading
                    excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
                    body: _rawBody(resolveReferences: { maxDepth: 10 })
                    featureImage {
                        ...ImageWithPreview
                    }
                    disciplines: _rawDiscipline(
                        resolveReferences: { maxDepth: 10 }
                    )
                }
            }
        }
    }
`

const WorkIndex = ( props ) => {
    // console.log( '🦄 ~ file: index.js ~ line 44 ~ WorkIndex ~ props', { props } )
    const { data } = props
    const work = mapEdgesToNodes( data.allSanityWork )

    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 356,
        md: 300,
        lg: 389,
        xl: 443
    } )

    return (
        <Layout>
            <SEO
                title='Our Work - Digital strategy, branding, websites & video'
                description='The opportunity to make a difference can be found anywhere. We work with people that seek to change the world for the better and are here to help them tell their stories.' />
            <Container maxWidth='8xl'>
                <Center flexDirection='column'>
                    <Heading
                        as='h1'
                        maxWidth='5xl'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center'
                        mt={12}
                        mb={24}
                        color='gray.100'
                        fontWeight='extrabold'>
                        Our impact.
                    </Heading>
                </Center>

                <SimpleGrid my={20} columns={[1, 2, 3]} spacing={[4, 6, 8, 10]}>
                    {work.map( ( { node } ) => (
                        <LinkBox 
                            key={node.id}
                            role='group'>
                            <Stack direction='column' pb={10} height='full'>
                                {node.featureImage && (
                                    <Image
                                        imageData={node.featureImage}
                                        loading='eager'
                                        width={imageWidths}
                                        height={400}
                                        sx={{
                                            width: 'full'
                                        }} />
                                )}
                                <LinkOverlay
                                    as={Link}
                                    to={`/work/${node.slug.current}/`}
                                    _hover={{ color: 'brand_secondary.500' }}>
                                    <Heading
                                        as='h2'
                                        fontSize={['lg', 'xl', '2xl', '3xl']}
                                        color='gray.100'
                                        transition='all 0.343s'
                                        _groupHover={{
                                            color: 'brand_secondary.500'
                                        }}
                                        pt={4}>
                                        {node.title}
                                    </Heading>
                                    <Text
                                        my={4}
                                        fontSize='md'
                                        maxWidth='2xl'
                                        mx='auto'
                                        color='gray.400'>
                                        {node.heading}
                                    </Text>
                                </LinkOverlay>
                                <Box pb={6}>
                                    {node.disciplines && (
                                        <Wrap spacing={2}>
                                            {node.disciplines
                                                .sort()
                                                .map( ( discipline ) => (
                                                    <WrapItem
                                                        key={discipline.id}>
                                                        <Tag
                                                            width='max'
                                                            whiteSpace='nowrap'
                                                            color='gray.200'
                                                            backgroundColor='whiteAlpha.300'>
                                                            {discipline.title}
                                                        </Tag>
                                                    </WrapItem>
                                                ) )}
                                        </Wrap>
                                    )}
                                </Box>
                            </Stack>
                        </LinkBox>
                    ) )}
                </SimpleGrid>
            </Container>

            <Container maxWidth='6xl'>
                <CallToAction />
            </Container>
        </Layout>
    )
}

export default WorkIndex
